import moment from 'moment';
import { useEffect, useState } from 'react';
import { dateToString } from '../../helpers/common';
import { API_ADMIN_USERS, API_AREAS } from '../../config';
import { AreaDetails, modalProps, userData } from '../../types';
import { showMessageUnderTheForm } from '../../helpers/forms';
import { logError } from '../../helpers/logger';

// TODO implement axios and separate class for API
// TODO FIX deleting last char from the input - now it doesn't work
// TODO Replace separate form fields with form generator
// TODO add errors processing and adding to page
// TODO add fields validation both on frontend and backend
// TODO FIX check why both GETs (user and area) are run twice from the frontend
// TODO check that such user name doesn't exist before creating
// TODO add user based on outlook contact view
// TODO fix areas editing
// TODO add ability to clear dates - now you can only change, but if you clear it will not be updated
// TODO fix when trying to edit the same user second time - it doesn't show its data unless you open another user and reopen
// TODO add message showing that user is added/edited

const emptyMessage = {
    message: '',
    error: ''
};

const emptyStringArray: string[] = [];

export const defaultUserData: userData = {
    userId: 0,
    login: '',
    name: '',
    fullName: '',
    areas: [{ id: 1, name: '' }],
    assignedAreas: emptyStringArray,
    activeFrom: moment().format('YYYY-MM-DD'),
    activeTill: ''
};

// TODO: Remove title from props - it is added temporary for analysis
export const UserForm = ({ setActive, id }: modalProps) => {
    const [state, setState] = useState({ ...defaultUserData, ...emptyMessage, action: 'add' });

    const getAreaList = () => {
        fetch(API_AREAS)
            .then((res: Response) => res.json())
            .then((json) => json.data)
            .then((areas) => {
                defaultUserData.areas = areas;
                setState({ ...state, areas });
            })
            .catch((err) => logError(`Can not get area list: ${err}`));
    };

    // TODO check format of available areas to show selected in the form
    // TODO add fetch for Patch after edit form submit
    // TODO change title of the submit button to show edit when edit and add when add
    // TODO fix new user add form to show default data instead of last edited user
    const getUserData = (userId: number) => {
        fetch(`${API_ADMIN_USERS}/${userId}`)
            .then((res: Response) => res.json())
            .then((json) => json.data)
            .then((userDataArray) => {
                const userData = userDataArray[0];
                userData.activeFrom = userData.activeFrom
                    ? dateToString(new Date(userData.activeFrom))
                    : '';
                userData.activeTill = userData.activeTill
                    ? dateToString(new Date(userData.activeTill))
                    : '';
                userData.assignedAreas = userData.Areas.map((item: AreaDetails) => item.id);
                userData.userId = userData.id;
                setState({
                    ...state,
                    ...userData,
                    action: 'edit'
                });
            })
            .catch((err) => logError(`Can not get user list: ${err}`));
    };

    const onGenericInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target?.name || '';
        const value = event.target?.value || '';
        key && setState({ ...state, [key]: value });
    };

    const onZonesAvaiableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const zonesSelect = event.target || '';
        const values = [];
        if (zonesSelect.multiple) {
            for (let i = 0; i < zonesSelect.options.length; i++) {
                if (zonesSelect.options[i].selected) values.push(zonesSelect.options[i].value);
            }
        } else values.push(zonesSelect.value);
        setState({ ...state, assignedAreas: values });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onUserSubmit = (event: any) => {
        event.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newItem: any = {
            login: event.target.login.value || '',
            activeFrom: dateToString(event.target.activeFrom.value) || moment().format('YYYY-MM-DD')
        };
        if (event.target.name.value) newItem.name = event.target.name.value;
        if (event.target.fullName.value) newItem.fullName = event.target.fullName.value;
        if (event.target.activeTill.value)
            newItem.activeTill = dateToString(event.target.activeTill.value);
        const reqBody = {
            user: newItem,
            areas: state.assignedAreas?.length
                ? state.assignedAreas.map((item) => Number(item))
                : []
        };

        //TODO: Replace with proper checks for each value and messages
        if (reqBody.user?.login && reqBody.user?.name && reqBody.areas.length) {
            fetch(`${API_ADMIN_USERS}${state.userId ? '/' + state.userId : ''}`, {
                body: JSON.stringify(reqBody),
                method: state.userId ? 'PATCH' : 'POST',
                headers: { 'Content-type': 'application/json' }
            })
                .then(async (res) => {
                    const msg = emptyMessage;
                    const result = await res.json();
                    if (res.status === 201) msg.message = result.message;
                    else msg.error = result.message;
                    // TODO Refresh table after insert and add popup on top saying of success or error message under the form
                    setActive(false);
                    setState({
                        ...state,
                        ...defaultUserData,
                        areas: state.areas,
                        error: msg.error,
                        message: msg.message
                    });
                })
                .catch((err) => {
                    logError(err);
                    setState({
                        ...state,
                        ...newItem,
                        error: err.message,
                        message: ''
                    });
                });
        } else {
            const errorMessage = 'Something went wrong - user login, name or areas are not defined';
            logError(errorMessage);
            setState({
                ...state,
                ...newItem,
                error: errorMessage
            });
        }
    };

    useEffect(() => {
        getAreaList();
    }, []);

    useEffect(() => {
        if (id) getUserData(id);
        else setState({ ...state, ...defaultUserData, ...emptyMessage, action: 'add' });
    }, [id]);

    return (
        <div className="UserForm">
            <form id="userForm" onSubmit={onUserSubmit}>
                <label htmlFor="login">
                    Логин<sup className="rednote">*</sup>
                </label>
                <br />
                <input
                    value={state.login}
                    autoComplete="off"
                    type="text"
                    name="login"
                    id="login"
                    placeholder="Логин, как в домене"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="name">
                    Краткое имя<sup className="rednote">*</sup>
                </label>
                <br />
                <input
                    value={state.name}
                    autoComplete="off"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Краткое имя для выпадашек"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="fullName">Полное имя</label>
                <br />
                <input
                    value={state.fullName}
                    autoComplete="off"
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Полное имя для идентификации при совпадающих инициалах"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="assignedAreas">
                    Доступные зоны<sup className="rednote">*</sup>
                </label>
                <br />
                <select
                    name="assignedAreas"
                    id="assignedAreas"
                    onChange={onZonesAvaiableChange}
                    value={state.assignedAreas}
                    multiple
                >
                    {state.areas?.map((item) => (
                        <option value={item.id} key={`areasKey-${item.id}`}>
                            {item.name}
                        </option>
                    ))}
                </select>
                <br />
                <label htmlFor="activeFrom">Дата начала </label>
                <br />
                <input
                    value={state.activeFrom}
                    type="date"
                    name="activeFrom"
                    id="activeFrom"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="activeTill">Дата завершения </label>
                <br />
                <input
                    value={state.activeTill}
                    type="date"
                    name="activeTill"
                    id="activeTill"
                    onChange={onGenericInputChange}
                />
                <br />
                <br />
                <input
                    type="submit"
                    value={state.action === 'edit' ? 'Редактировать' : 'Добавить'}
                />
            </form>
            {state.error !== '' && showMessageUnderTheForm(state.error)}
        </div>
    );
};
