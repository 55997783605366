import { Link } from 'react-router-dom';
import { anyObject, tableField } from '../types';
import { today } from './common';

export const tablePrinter = (
    tableName: string,
    items: anyObject[],
    fields: tableField[],
    highlight: anyObject = {}
) => {
    const fieldHeaders = (tableName: string, fields: tableField[]) => (
        <tr key={`${tableName}-header`}>
            {fields.map((item: tableField) => (
                <th key={`${tableName}-${item.name}`}>{item.name}</th>
            ))}
        </tr>
    );

    const isItemActive = (item: anyObject): boolean => {
        if (item?.deletedAt) return false;
        if (item?.activeTill && new Date(item?.activeTill?.split('T')[0]) < today) return false;
        return true;
    };

    const defineClassName = (item: anyObject) => {
        let className = '';
        className = isItemActive(item) ? '' : 'graycell';
        for (const key in highlight) {
            if (key in item && item[key] == highlight[key]) className = 'greencell';
        }
        return className;
    };
    const fieldValues = (tableName: string, items: anyObject[], fields: tableField[]) => {
        let i = 0;
        return items.map((item: anyObject) => (
            <tr key={`${tableName}-${i++}`}>
                {fields.map((field) => {
                    let value = item[field.name] ? item[field.name] : '';
                    value = field.valueProcessor ? field.valueProcessor(value) : value;
                    const jsxValue = field.editLink ? (
                        <Link to={`${tableName}/${item.id}`}>{`${value}`}</Link>
                    ) : (
                        <div>{`${value}`}</div>
                    );
                    return (
                        <td
                            key={`${tableName}-${i++}-${field.name}`}
                            className={defineClassName(item)}
                        >
                            {jsxValue}
                        </td>
                    );
                })}
            </tr>
        ));
    };

    return (
        <table>
            <thead>{fieldHeaders(tableName, fields)}</thead>
            <tbody>{fieldValues(tableName, items, fields)}</tbody>
        </table>
    );
};
