import { useState, useEffect } from 'react';
import { API_ADMIN_USERS } from '../../config';
import { logError } from '../../helpers/logger';
import { tablePrinter } from '../../helpers/tablePrinter';
import { anyObject, tableField } from '../../types';
import { datetime2dateProcessor } from '../../helpers/common';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const UserList = ({ highlight }) => {
    const [state, setState] = useState({
        userList: []
    });

    useEffect(() => {
        getUserList();
    }, []);

    const getUserList = () => {
        fetch(API_ADMIN_USERS)
            .then((res: Response) => res.json())
            .then((json) => json.data)
            .then((userList) => {
                setState({ ...state, userList });
            })
            .catch((err) => logError(`Can not get user list: ${err}`));
    };

    const areasProcessor = (field: anyObject[]) =>
        field.reduce(
            (acc: string, currentValue: anyObject) =>
                acc ? `${acc}; ${currentValue.name}` : `${currentValue.name}`,
            ''
        );

    const fields: tableField[] = [
        { name: 'id' },
        { name: 'login', editLink: true },
        { name: 'name' },
        { name: 'fullName' },
        { name: 'Areas', valueProcessor: areasProcessor },
        { name: 'activeFrom', valueProcessor: datetime2dateProcessor },
        { name: 'activeTill', valueProcessor: datetime2dateProcessor },
        { name: 'deletedAt', valueProcessor: datetime2dateProcessor }
    ];

    return tablePrinter('users', state.userList, fields, highlight);
};
