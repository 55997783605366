/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { logError } from '../helpers/logger';
import { dateToString, encodeQueryData, showAlert, today, tomorrow } from '../helpers/common';
import {
    AreaDetails,
    BookingListItem,
    DateBookingItem,
    DeskAndBookingList,
    UnderFormMessageType,
    User
} from '../types';
import { showOfficePicture } from './main/officePicture';
import { API_USERS, API_AVAILABLE_DESKS, API_BOOKING, MAX_BOOKING_DAYS } from '../config';
import { showDateBookingList } from './main/dateBookingList';
import { showMessageUnderTheForm } from '../helpers/forms';

const cookies = new Cookies();

const CURRENT_USER_UUID = cookies.get('UUID') || uuidv4();

cookies.set('UUID', CURRENT_USER_UUID, {
    path: '/',
    secure: true,
    sameSite: 'strict',
    maxAge: 2678400
});
const CURRENT_USER_UUID_1ST = CURRENT_USER_UUID.split('-')[0];

const defaultBookingList: BookingListItem[] = [];
const defaultDateBookingList: DateBookingItem[] = [];
const defaultAreaList: AreaDetails[] = [];

const Main = () => {
    const [state, setState] = useState({
        userId: Number(localStorage.getItem('lastUserId')) || 0,
        userName: String(localStorage.getItem('lastUserName')) || '',
        deskId: Number(localStorage.getItem('lastDeskId')) || 0,
        areaId: Number(localStorage.getItem('lastAreaId')) || 0,
        bookingDateString: dateToString(tomorrow()),
        bookingShift: 0,
        userList: [],
        userBookingList: defaultBookingList,
        dateBookingList: defaultDateBookingList,
        availableDesks: defaultAreaList,
        message: '',
        error: ''
    });

    const handleError = (
        error: string,
        userId = state.userId,
        bookingDateString = state.bookingDateString
    ) => {
        logError(error);
        setState({ ...state, userId, bookingDateString, error });
    };

    const getUserList = () => {
        fetch(API_USERS)
            .then((res: Response) => res.json())
            .then((json) => json.data)
            .then((userList) => {
                if (state.userId) {
                    getDeskAndBookingList(
                        state.userId,
                        state.bookingDateString,
                        state.bookingShift,
                        emptyMessage,
                        userList
                    );
                } else {
                    setState({ ...state, userList });
                }
            })
            .catch((err) => logError(`Can not get user list: ${err}`));
    };

    const emptyMessage = {
        message: '',
        error: ''
    };

    const defineSelectedUserName = (userId = state.userId, userList = state.userList) => {
        const selectedUser: { id: number; name: string } = userList.find(
            (item: { id: number; name: string }) => item.id == userId
        ) || { id: 0, name: '' };
        return selectedUser.name;
    };
    // Main GET
    const getDeskAndBookingList = (
        userId: number,
        bookingDateString: string,
        bookingShift = 0,
        msg = emptyMessage,
        userList = state.userList
    ) => {
        localStorage.setItem('lastUserId', String(userId));
        const userName = defineSelectedUserName(userId, userList);
        localStorage.setItem('lastUserName', userName);
        if (userId && bookingDateString) {
            const queryData = { userId, bookingDate: bookingDateString, bookingShift };

            const availableDesks: Promise<AreaDetails[]> = fetch(
                `${API_AVAILABLE_DESKS}?${encodeQueryData(queryData)}`
            )
                .then((res) => res.json())
                .then((json) => {
                    return typeof json.data == typeof availableDesks ? json.data : [];
                })
                .catch((err) => {
                    logError(err);
                    return [];
                });

            const userBookingList: Promise<BookingListItem[]> = fetch(
                `${API_BOOKING}/user/${userId}`
            )
                .then((res) => res.json())
                .then((json) => (typeof json.data == typeof userBookingList ? json.data : []))
                .catch((err) => {
                    logError(err);
                    return [];
                });

            const dateBookingList: Promise<DateBookingItem[]> = fetch(
                `${API_BOOKING}/date/${bookingDateString}?user=${userId}`
            )
                .then((res) => res.json())
                .then((json) => {
                    return typeof json.data == typeof [] ? json.data : [];
                })
                .catch(() => []);

            Promise.all([availableDesks, userBookingList, dateBookingList])
                .then((res: DeskAndBookingList) => {
                    const [availableDesks, userBookingList, dateBookingList] = res;
                    let areaId = state.areaId;
                    if (availableDesks.length) {
                        const someAreaAvailableDesks = availableDesks.find(
                            (item) => item.id === state.areaId
                        );
                        areaId = someAreaAvailableDesks?.id
                            ? someAreaAvailableDesks.id
                            : availableDesks[0].id;
                    }
                    setState({
                        ...state,
                        ...msg,
                        userList,
                        userId,
                        userName,
                        areaId,
                        bookingDateString,
                        bookingShift,
                        availableDesks,
                        userBookingList,
                        dateBookingList
                    });
                })
                .catch((err) =>
                    handleError(
                        `Couldn't fetch data for desks and booking: ${err}`,
                        userId,
                        bookingDateString
                    )
                );
        } else handleError('Выберите дату и для кого бронируется', userId, bookingDateString);
    };

    if (state.message) showAlert(state.message);

    const showUserList = (userList: User[]) => {
        if (userList.length > 0)
            return userList?.map((user: User) => (
                <option key={'optionDesk-' + user.id} value={user.id}>
                    {user.name}
                </option>
            ));
    };

    const showAreaList = (desks: AreaDetails[] = []) => {
        return desks.map((area) => (
            <option key={'area-' + area.id} value={area.id}>
                {'' + area.name}
            </option>
        ));
    };

    const showDeskList = (availableDesks: AreaDetails[], areaId = 0) => {
        if (!areaId) logError(`areaId is not set`);
        if (availableDesks.length) {
            let area = availableDesks.find((areaDesk) => (areaId ? areaDesk.id === areaId : true));
            if (!area?.id) area = availableDesks[0];
            const desks = area?.desks?.length ? area.desks : [];
            desks?.sort((a, b) => Number(a.name) - Number(b.name));
            return desks?.map((desk) => (
                <option key={'optionDesk-' + desk.id} value={desk.id}>
                    {'' + desk.name}
                </option>
            ));
        } else return <option></option>;
    };

    const onDatePickerChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        event.target?.value &&
        getDeskAndBookingList(state.userId, event.target.value, state.bookingShift);

    const onBookingShiftChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        (Number(event.target?.value) === 0 || Number(event.target?.value)) &&
        getDeskAndBookingList(state.userId, state.bookingDateString, Number(event.target?.value));

    const onUserIdSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
        Number(event.target?.value) &&
        getDeskAndBookingList(
            Number(event.target.value),
            state.bookingDateString,
            state.bookingShift
        );

    // TODO replace any with React.FormEvent<HTMLFormElement> extended by form fields
    // [POST] Submit booking
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onBookingSubmit = (event: any) => {
        event.preventDefault();
        const areaId = Number(event.target.areaIdString.value);
        const deskId = Number(event.target.deskIdString.value);
        const userId = Number(event.target.userIdString.value);
        const bookingDateString = dateToString(event.target.bookingDate.value);
        const bookingShift = Number(event.target.bookingShift.value);

        // TODO implement axios and separate class for API
        if (userId) {
            if (userId) localStorage.setItem('lastUserId', String(userId));
            if (areaId) localStorage.setItem('lastAreaId', String(areaId));
            if (deskId) localStorage.setItem('lastDeskId', String(deskId));
            fetch(`${API_BOOKING}`, {
                body: JSON.stringify({
                    userId,
                    deskId,
                    bookingDate: bookingDateString,
                    bookingShift,
                    bookerId: CURRENT_USER_UUID
                }),
                method: 'POST',
                headers: { 'Content-type': 'application/json' }
            })
                .then(async (res) => {
                    const msg = emptyMessage;
                    const result = await res.json();
                    if (res.status === 201) msg.message = result.message;
                    else msg.error = result.message;
                    return msg;
                })
                .then((msg) => getDeskAndBookingList(userId, bookingDateString, bookingShift, msg))
                .catch((err) => {
                    logError(err);
                    setState({
                        ...state,
                        userId,
                        deskId,
                        areaId,
                        bookingDateString,
                        bookingShift,
                        error: err.message,
                        message: ''
                    });
                });
        } else {
            logError('Something went wrong');
        }
    };

    const showBookingForm = () => {
        //TODO Disable booking shifts which are not available once we get this knowledge from backend
        const enableBooking: boolean =
            Number(state.availableDesks[0]?.desks?.length) > 0 && state.userId != 0;
        return (
            <form id="userForm" onSubmit={onBookingSubmit}>
                <label>Дата</label>
                <br />
                <input
                    value={state.bookingDateString}
                    type="date"
                    min={dateToString(today)}
                    max={moment().add(MAX_BOOKING_DAYS, 'days').format('YYYY-MM-DD')}
                    name="bookingDate"
                    onChange={onDatePickerChange}
                />

                <div>
                    <input
                        value="0"
                        type="radio"
                        name="bookingShift"
                        id="bookingShiftFullDate"
                        onChange={onBookingShiftChange}
                        checked={state.bookingShift == 0}
                    />
                    Весь день
                    <input
                        value="1"
                        type="radio"
                        name="bookingShift"
                        id="bookingShiftFullDate"
                        onChange={onBookingShiftChange}
                        checked={state.bookingShift == 1}
                    />
                    8:00-13:00
                    <input
                        value="2"
                        type="radio"
                        name="bookingShift"
                        id="bookingShiftFullDate"
                        onChange={onBookingShiftChange}
                        checked={state.bookingShift == 2}
                    />
                    13:00-18:00
                </div>
                {
                    //TODO Заменить саджестом  для выбора пользователя
                }
                <br />
                <label>Для кого бронируется</label>
                <br />
                <select name="userIdString" value={state.userId} onChange={onUserIdSelectChange}>
                    <option key="optionDesk-0" value="0">
                        --- Выберите пользователя ---
                    </option>
                    {showUserList(state.userList)}
                </select>
                <br />
                <label>Где</label>
                <br />
                <select
                    value={state.areaId}
                    name="areaIdString"
                    disabled={!enableBooking}
                    onChange={(event) => setState({ ...state, areaId: Number(event.target.value) })}
                >
                    {showAreaList(state.availableDesks)}
                </select>
                <br />
                <label>Номер стола</label>
                <br />
                <select
                    name="deskIdString"
                    value={state.deskId}
                    disabled={!enableBooking}
                    onChange={(event) => setState({ ...state, deskId: Number(event.target.value) })}
                >
                    {showDeskList(state.availableDesks, state.areaId)}
                </select>
                <br />
                <br />
                <input type="submit" value="Забронировать" disabled={!enableBooking} />
            </form>
        );
    };

    // [DELETE] Cancel booking
    const removeBookingItem = (itemId: number) => {
        fetch(`${API_BOOKING}/${itemId}`, { method: 'DELETE' })
            .then((res) => {
                if (res.status == 200) getUserList();
            })
            .catch((err) => logError(err));
    };

    const bookingDateValuesExt = ['', ' [УТРО]', ' [ВЕЧЕР]'];

    // const showBookedDates = (date1: string, date2: string, text: string, bookerId = '') => (
    const showBookedDates = (item: BookingListItem, bookingDateString: string) => {
        const bookingDate = dateToString(item.bookingDate);
        const bookingShift = bookingDateValuesExt[item.bookingShift || 0];
        // const cancelSymbol = '&#215;';
        const text = `${item.areaName}, стол ${item.deskName}`;
        return (
            <div className={bookingDate === bookingDateString ? 'bold' : ''}>
                {bookingDate}
                {bookingShift}: {text}{' '}
                {item.bookerId == CURRENT_USER_UUID_1ST ? (
                    <a
                        href="#"
                        onClick={() => removeBookingItem(item.id)}
                        title="Отменить бронирование"
                    >
                        &#x2715;
                    </a>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const showUserBookingList = (bookingList: BookingListItem[], bookingDateString: string) => {
        return (
            <div>
                Бронирования{' '}
                <b>{state.userList.map((user: User) => user.id === state.userId && user.name)}</b>:
                <ul key="userBookingList">
                    {bookingList.map((bookingItem: BookingListItem) => (
                        <li key={`bookingListItem-${bookingItem.id}`}>
                            {showBookedDates(bookingItem, bookingDateString)}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    useEffect(() => {
        getUserList();
    }, []);

    let underFormMessage = 'Выберите дату и нажмите "Забронировать"';
    let underFormMessageType: UnderFormMessageType = 'greynote';

    if (state.availableDesks?.length == 0 && state.userId != 0 && !state.message) {
        underFormMessage = 'На выбранную дату свободных столов нет';
        underFormMessageType = 'rednote';
    }

    if (state.dateBookingList.find((item) => item.userName == state.userName)) {
        underFormMessage = 'На выбранную дату уже есть бронь';
        underFormMessageType = 'greennote';
    }

    return (
        <div className="main_div">
            <div className="content_div">
                <div className="content_block">
                    {showBookingForm()}
                    {showMessageUnderTheForm(underFormMessage, underFormMessageType)}
                    {state.error !== '' && showMessageUnderTheForm(state.error)}
                </div>
                <div className="content_block">{showOfficePicture(state)}</div>
            </div>
            <div className="content_div">
                <div className="content_block">
                    {state.userBookingList?.length > 0 && state.userId != 0
                        ? showUserBookingList(state.userBookingList, state.bookingDateString)
                        : ''}
                </div>
                <div className="content_block">
                    {state.dateBookingList?.length
                        ? showDateBookingList(
                              state.dateBookingList,
                              state.bookingDateString,
                              state.userName
                          )
                        : ''}
                </div>
            </div>
        </div>
    );
};

export default Main;
