import { useEffect, useState } from 'react';

import { UserForm } from './userForm';
import { UserList } from './userList';
import Modal from '../modal/modal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isNumber } from '../../helpers/common';

export const Users = () => {
    const id = useParams().id;
    const userId = id ? Number.parseInt(id || '') : 0;
    const navigate = useNavigate();

    const [state, setState] = useState({
        modalTitle: '',
        modalActive: false,
        modalClosureRedirectLocation: '/admin/users',
        listItemHighlight: {},
        userId
    });
    const location = useLocation();

    const UserAddButton = () => (
        <div className="addButton">
            <input type="button" value="Добавить" accessKey="a" onClick={() => showUserForm()} />
        </div>
    );

    const showUserForm = (userId = 0) => {
        const modalTitle = userId
            ? `Редактирование пользователя: ${userId}`
            : 'Добавление пользователя';
        setState({ ...state, userId, modalTitle, modalActive: true });
    };

    const showModal = (show = false) => {
        if (show) setState({ ...state, modalActive: true });
        else {
            if (state.modalClosureRedirectLocation) navigate(state.modalClosureRedirectLocation);
            const listItemHighlight = { id: state.userId };
            // TODO reload userList on modal closure
            setState({ ...state, modalActive: false, listItemHighlight });
        }
    };

    useEffect(() => {
        if (userId && isNumber(userId)) showUserForm(userId);
        else setState({ ...state, modalActive: false });
    }, [location]);

    return (
        <div>
            <UserAddButton />
            <UserList highlight={state.listItemHighlight} />
            <UserAddButton />
            <Modal title={state.modalTitle} active={state.modalActive} setActive={showModal}>
                <UserForm setActive={showModal} id={state.userId} />
            </Modal>
        </div>
    );
};
