// TODO implement something like dotenv
// export const API_BASE_URL = 'http://localhost/8000'; // removed after moving to docker as main way
export const API_BASE = '/api';
export const API_USERS = `${API_BASE}/users`;
export const API_AVAILABLE_DESKS = `${API_BASE}/desks`;
export const API_BOOKING = `${API_BASE}/bookings`;
export const API_AREAS = `${API_BASE}/areas`;
export const API_ADMIN_USERS = `${API_BASE}/admin/users`;
export const MAX_BOOKING_DAYS = 21;

export const DEBUG = true;
