import '../css/styles.css';
import { Users } from './admin/users';

const Admin = () => {
    return (
        <div>
            <h2>Admin Area</h2>
            <div className="adminContent">
                <Users />
            </div>
        </div>
    );
};

export default Admin;
